<template>
  <div>
    <!-- <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{message}}</span>
		</v-snackbar> -->
    <v-dialog
      v-model="loginCardState"
      persistent
      transition="dialog-bottom-transition"
      max-width="700"
      fullscreen
      v-if="$vuetify.breakpoint.xsOnly"
    >
      <v-card :loading="loading" class="elevation-1" max-width="700">
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="2"
            indeterminate
          ></v-progress-linear>
        </template>
        <div v-if="authKind == 'login'" style="z-index: 1000">
          <v-toolbar dark flat color="white">
            <div class="black--text headline font-weight-bold">
              IDENTIFICATION
            </div>
            <v-spacer></v-spacer>
            <v-divider vertical></v-divider>
            <v-btn icon dark @click="changeLoginCardState">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <v-divider></v-divider>
        <div v-if="authKind == 'create'">
          <v-toolbar dark flat color="white">
            <v-btn icon dark @click="changeFormLogin">
              <v-icon large color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            <div class="black--text headline font-weight-bold">
              CREATE A NEW ACCOUNT
            </div>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="changeLoginCardState">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <v-divider></v-divider>
        <v-img v-if="authKind == 'password'" height="300" src="img/home1.jpg">
          <v-toolbar dark flat color="white">
            <v-btn icon dark @click="changeFormLogin">
              <v-icon large color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="changeLoginCardState">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-img>
        <v-alert
          class="mx-2 mt-2"
          v-if="snackbar"
          dense
          text
          dismissible
          :type="alertType"
        >
          {{ message }}
        </v-alert>
        <div v-if="authKind == 'login'">
          <v-form ref="loginForm" v-model="valid" lazy-validation>
            <v-card-text>
              <div class="mx-3">
                <div class="primary--text mt-5 title">
                  I ALREADY HAVE AN ACCOUNT
                </div>
                <div class="mt-5 caption">Email*</div>
                <v-text-field
                  outlined
                  v-model="email"
                  :rules="emailRules"
                  required
                ></v-text-field>
                <div class="caption">Password*</div>
                <v-text-field
                  outlined
                  v-model="password"
                  :rules="inputRules"
                  type="password"
                ></v-text-field>
                <a
                  class="primary--text mb-2 caption"
                  @click="changeFormPassword"
                  >Forgot Password?</a
                >
                <v-btn
                  depressed
                  block
                  large
                  color="primary"
                  :disabled="!valid"
                  @click="login"
                  :loading="loading"
                  class="text-none mb-5"
                >
                  Sign In
                </v-btn>
              </div>
            </v-card-text>
            <v-divider class="my-5"></v-divider>
            <v-card-text>
              <div class="mx-3">
                <div class="primary--text mt-2 title">
                  I DON'T HAVE AN ACCOUNT
                </div>
                <p class="caption">
                  Enjoy added benefits and richer experience by creating a
                  personal account.
                </p>
                <v-btn
                  depressed
                  block
                  large
                  color="primary"
                  class="mt-5 text-none mb-10"
                  @click="changeForm"
                  >Create My Account</v-btn
                >
              </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="authKind == 'create'">
          <v-card-text>
            <div class="mx-3">
              <v-form ref="form" v-model="valid" lazy-validation>
                <div v-if="level == 'stepOne'">
                  <div class="primary--text mt-5 title">
                    LOGIN INFORMATION(1/2)
                  </div>

                  <div class="mt-5 caption">Email*</div>
                  <v-text-field
                    outlined
                    v-model="userData.email"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                  <div class="caption">Password*</div>
                  <v-text-field
                    outlined
                    v-model="userData.password"
                    type="password"
                    :rules="passwordRules"
                    required
                  ></v-text-field>
                  <div class="caption">Confirm Password*</div>
                  <v-text-field
                    outlined
                    v-model="confirmpassword"
                    type="password"
                    :rules="passwordConfirmRules"
                    required
                  ></v-text-field>
                  <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    :disabled="!valid"
                    @click="nextStep"
                    class="text-none mb-10"
                  >
                    Next
                  </v-btn>
                </div>
                <div v-if="level == 'stepTwo'">
                  <div class="primary--text mt-5 title">
                    PERSONAL INFORMATION(2/2)
                  </div>
                  <div class="mt-5 caption">First Name*</div>
                  <v-text-field
                    outlined
                    v-model="userData.firstName"
                    :rules="inputRules"
                    required
                  ></v-text-field>
                  <div class="caption">Last Name*</div>
                  <v-text-field
                    outlined
                    v-model="userData.lastName"
                    :rules="inputRules"
                    required
                  ></v-text-field>
                  <div class="caption">Phone Number*</div>
                  <v-text-field
                    outlined
                    v-model="userData.phoneNumber"
                    :rules="inputRules"
                    required
                  ></v-text-field>
                  <div class="caption">Referral Code</div>
                  <v-text-field
                    outlined
                    v-model="userData.referral_code"
                    required
                  ></v-text-field>
                  <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    :disabled="!valid"
                    @click="create"
                    :loading="loading"
                    class="text-none"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    outlined
                    block
                    large
                    color="primary"
                    @click="level = 'stepOne'"
                    class="text-none mb-10 mt-5"
                  >
                    Back
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-card-text>
        </div>
        <div v-if="authKind == 'password'">
          <v-form ref="passwordForm" v-model="valid" lazy-validation>
            <v-card-text>
              <v-card-title class="primary--text">Forgot Password</v-card-title>
              <v-text-field
                filled
                dense
                v-model="userData.email"
                :rules="emailRules"
                prepend-inner-icon="mdi-email-outline"
                label="E-mail"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-layout column>
                <v-flex xs12>
                  <v-btn
                    depressed
                    block
                    large
                    color="accent"
                    :disabled="!valid"
                    @click="changePassword"
                    :loading="loading"
                    class="text-none"
                  >
                    Send me a link..
                    <v-icon dark right>mdi-login</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12>
                  <v-btn
                    class="secondary--text mt-5 text-none"
                    text
                    block
                    @click="changeForm"
                    >Create an account</v-btn
                  >
                </v-flex>
                <v-flex xs12>
                  <v-btn
                    class="primary--text mt-2 mb-2 text-none"
                    text
                    block
                    @click="changeFormLogin"
                    >Back to Login</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
    style="z-index: 600"
      v-model="loginCardState"
      persistent
      transition="dialog-top-transition"
      max-width="700"
      v-if="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly"
    >
      <v-card :loading="loading" class="elevation-1" max-width="700">
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="2"
            indeterminate
          ></v-progress-linear>
        </template>
        <div v-if="authKind == 'login'">
          <v-toolbar dark flat color="white">
            <div class="black--text headline font-weight-bold">
              IDENTIFICATION
            </div>
            <v-spacer></v-spacer>
            <v-divider vertical></v-divider>
            <v-btn icon dark @click="changeLoginCardState">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <v-divider></v-divider>
        <div v-if="authKind == 'create'">
          <v-toolbar dark flat color="white">
            <v-btn icon dark @click="changeFormLogin">
              <v-icon large color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            <div class="black--text headline font-weight-bold">
              CREATE A NEW ACCOUNT
            </div>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="changeLoginCardState">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </div>
        <v-divider></v-divider>
        <v-img v-if="authKind == 'password'" height="300" src="img/home1.jpg">
          <v-toolbar dark flat color="white">
            <v-btn icon dark @click="changeFormLogin">
              <v-icon large color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="changeLoginCardState">
              <v-icon large color="primary">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-img>
        <v-alert
          class="mx-2 mt-2"
          v-if="snackbar"
          dense
          text
          dismissible
          :type="alertType"
        >
          {{ message }}
        </v-alert>
        <div v-if="authKind == 'login'">
          <v-form ref="loginForm" v-model="valid" lazy-validation>
            <v-card-text>
              <div class="mx-3">
                <div class="primary--text mt-5 title">
                  I ALREADY HAVE AN ACCOUNT
                </div>
                <div class="mt-5 caption">Email*</div>
                <v-text-field
                  outlined
                  v-model="email"
                  :rules="emailRules"
                  required
                ></v-text-field>
                <div class="caption">Password*</div>
                <v-text-field
                  outlined
                  v-model="password"
                  :rules="inputRules"
                  type="password"
                ></v-text-field>
                <a
                  class="primary--text mb-2 caption"
                  @click="changeFormPassword"
                  >Forgot Password?</a
                >
                <v-btn
                  depressed
                  block
                  large
                  color="primary"
                  :disabled="!valid"
                  @click="login"
                  :loading="loading"
                  class="text-none mb-5"
                >
                  Sign In
                </v-btn>
              </div>
            </v-card-text>
            <v-divider class="my-5"></v-divider>
            <v-card-text>
              <div class="mx-3">
                <div class="primary--text mt-2 title">
                  I DON'T HAVE AN ACCOUNT
                </div>
                <p class="caption">
                  Enjoy added benefits and richer experience by creating a
                  personal account.
                </p>
                <v-btn
                  depressed
                  block
                  large
                  color="primary"
                  class="mt-5 text-none mb-10"
                  @click="changeForm"
                  >Create My Account</v-btn
                >
              </div>
            </v-card-text>
          </v-form>
        </div>
        <div v-if="authKind == 'create'">
          <v-card-text>
            <div class="mx-3">
              <v-form ref="form" v-model="valid" lazy-validation>
                <div v-if="level == 'stepOne'">
                  <div class="primary--text mt-5 title">
                    LOGIN INFORMATION(1/2)
                  </div>

                  <div class="mt-5 caption">Email*</div>
                  <v-text-field
                    outlined
                    v-model="userData.email"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                  <div class="caption">Password*</div>
                  <v-text-field
                    outlined
                    v-model="userData.password"
                    type="password"
                    :rules="passwordRules"
                    required
                  ></v-text-field>
                  <div class="caption">Confirm Password*</div>
                  <v-text-field
                    outlined
                    v-model="confirmpassword"
                    type="password"
                    :rules="passwordConfirmRules"
                    required
                  ></v-text-field>
                  <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    :disabled="!valid"
                    @click="nextStep"
                    class="text-none mb-10"
                  >
                    Next
                  </v-btn>
                </div>
                <div v-if="level == 'stepTwo'">
                  <div class="primary--text mt-5 title">
                    PERSONAL INFORMATION(2/2)
                  </div>
                  <div class="mt-5 caption">First Name*</div>
                  <v-text-field
                    outlined
                    v-model="userData.firstName"
                    :rules="inputRules"
                    required
                  ></v-text-field>
                  <div class="caption">Last Name*</div>
                  <v-text-field
                    outlined
                    v-model="userData.lastName"
                    :rules="inputRules"
                    required
                  ></v-text-field>
                  <div class="caption">Phone Number*</div>
                  <v-text-field
                    outlined
                    v-model="userData.phoneNumber"
                    :rules="inputRules"
                    required
                  ></v-text-field>
                  <div class="caption">Referral Code</div>
                  <v-text-field
                    outlined
                    v-model="userData.referral_code"
                    required
                  ></v-text-field>
                  <v-btn
                    depressed
                    block
                    large
                    color="primary"
                    :disabled="!valid"
                    @click="create"
                    :loading="loading"
                    class="text-none"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    outlined
                    block
                    large
                    color="primary"
                    @click="level = 'stepOne'"
                    class="text-none mb-10 mt-5"
                  >
                    Back
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-card-text>
        </div>
        <div v-if="authKind == 'password'">
          <v-form ref="passwordForm" v-model="valid" lazy-validation>
            <v-card-text>
              <v-card-title class="primary--text">Forgot Password</v-card-title>
              <v-text-field
                filled
                dense
                v-model="userData.email"
                :rules="emailRules"
                prepend-inner-icon="mdi-email-outline"
                label="E-mail"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-layout column>
                <v-flex xs12>
                  <v-btn
                    depressed
                    block
                    large
                    color="accent"
                    :disabled="!valid"
                    @click="changePassword"
                    :loading="loading"
                    class="text-none"
                  >
                    Send me a link..
                    <v-icon dark right>mdi-login</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12>
                  <v-btn
                    class="secondary--text mt-5 text-none"
                    text
                    block
                    @click="changeForm"
                    >Create an account</v-btn
                  >
                </v-flex>
                <v-flex xs12>
                  <v-btn
                    class="primary--text mt-2 mb-2 text-none"
                    text
                    block
                    @click="changeFormLogin"
                    >Back to Login</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import { AUTH_REQUEST } from "@/store/actions/auth";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "AuthPopUp",

  data: () => {
    return {
      alertType: "",
      authKind: "login",
      snackbar: false,
      message: "",
      color: "",
      loading: false,
      valid: true,

      level: "stepOne",

      confirmpassword: "",

      passwordConfirmRules: [(v) => !!v || " "],

      inputRules: [(v) => !!v || " "],

      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Minimum 8 characters",
        (v) =>
          /(?=.*[A-Z])/.test(v) || "Must have at least one uppercase character",
        (v) => /(?=.*\d)/.test(v) || "Must have at least one number",
        (v) =>
          /([!@$%.])/.test(v) ||
          "Must have at least one special character [!@#$%.]",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      userData: {
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        referral_code: null,
      },
    };
  },

  methods: {
    ...mapActions([
      "closeNav","changeLoginCardState"]),
    reset() {
      this.$refs.form.reset();
    },
    nextStep() {
      if (this.$refs.form.validate()) {
        if (this.userData.password != this.confirmpassword) {
          this.message = "Passwords do not match";
          this.color = "error";
          this.snackbar = true;
          this.alertType = "error";
        } else {
          this.level = "stepTwo";
        }
      }
    },

    login: function () {
      this.snackbar = false;
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        const { email, password } = this;
        this.$store
          .dispatch(AUTH_REQUEST, { email, password })
          .then((resp) => {
              if(resp.status==404){
                  this.message = resp.message;
                this.loading = false;
                this.snackbar = true;
                this.alertType = "error";
              }else if(resp.status==403){
                  this.message = resp.message;
                this.loading = false;
                this.snackbar = true;
                this.alertType = "error";
              }else if(resp.status==401){
                  this.message = resp.message;
                this.loading = false;
                this.snackbar = true;
                this.alertType = "error";
              }else{
                  this.$store.dispatch("changeLoginCardState");
                this.loading = false;
              }
            
          })
          .catch((resp) => {
            this.message = "Something went wrong, kindly try again.";
            this.loading = false;
            this.snackbar = true;
            this.alertType = "error";
          });
      }
    },
    changePassword() {
      if (this.$refs.passwordForm.validate()) {
        this.loading = true;
        apiCall({
          url: "/api/user/reset-password",
          data: this.userData,
          method: "POST",
        })
          .then((resp) => {
            this.message = "We've sent you a reset link via email.";
            this.color = "success";
            this.loading = false;
            this.snackbar = true;
            this.authKind = "login";
            this.alertType = "success";
          })
          .catch((error) => {
            this.message = "Account does not exist";
            this.loading = false;
            this.snackbar = true;
            this.alertType = "error";
          });
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        if (this.userData.password != this.confirmpassword) {
          this.message = "Passwords do not match";
          this.color = "error";
          this.snackbar = true;
          this.alertType = "error";
        } else {
          this.loading = true;
          this.snackbar = false;
          apiCall({ url: "/api/register", data: this.userData, method: "POST" })
            .then((resp) => {
              if (resp.status == 409) {
                this.$nextTick(() => {
                  this.message = resp.message;
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.alertType = "error";
                });
              } else if(resp.status == 404) {
                this.$nextTick(() => {
                  this.message = resp.message;
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.alertType = "error";
                });
              }
              
              else {
                this.$nextTick(() => {
                  this.message =
                    "Account Created. We've sent you an activation link via email.";
                  this.color = "success";
                  this.loading = false;
                  this.snackbar = true;
                  this.authKind = "login";
                  this.alertType = "success";
                });
              }
            })
            .catch((error) => {
              this.message = "Something went wrong, kindly try again.";
              this.loading = false;
              this.snackbar = true;
              this.alertType = "error";
            });
        }
      }
    },
    changeForm() {
      this.authKind = "create";
      this.snackbar = false;
    },
    changeFormLogin() {
      this.authKind = "login";
      this.snackbar = false;
    },
    changeFormPassword() {
      this.authKind = "password";
      this.snackbar = false;
    },
  },
  computed: {
    ...mapGetters(["loginCardState", "nationalities", "genders"]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
    }),
  },
};
</script>